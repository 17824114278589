import { useEffect, useState } from 'react';
import { useSelector, useDispatch } from "react-redux";
import { MdOutlineLooksOne } from 'react-icons/md';
import { loadLeaderboardUsers } from '../../../store/actions/student.leaderboard';
import Spinner from '../spinner';

// Sample JSON data
// const leaderboardData = [
//   {
//     names: "Christian m",
//     email: "####deleted###rutacris21@gmail.com",
//     subjects_count: 6,
//     combined_marks: 39.54
//   },
//   {
//     names: "llfsfsf kgdkjggjk",
//     email: "student0001@opanda.xyz",
//     subjects_count: 3,
//     combined_marks: 15.84
//   }
// ];


const Leaderboard = () => {

  const dispatch = useDispatch();

  const userAth = useSelector((state) => state.auth);
  const leaderboardData = useSelector((state) => state.Leaderboard.data[0]);
  const leaderboardloader = useSelector((state) => state.Leaderboard.loading);
  const lastWeek = new Date();
  lastWeek.setDate(lastWeek.getDate() - 7);
  const startDate = lastWeek.toISOString().split('T')[0];
  useEffect(() => {
    dispatch(
      loadLeaderboardUsers({
        token: userAth.token,
        academic_level: userAth.academic_level,
        // startDate: "2022-01-22",
        // endDate: "2024-08-23"
        startDate: startDate,
        endDate: new Date().toISOString().split('T')[0]
      })
    );
  }, [dispatch, userAth.token, userAth.academic_level]);


  return (
    <div className="bg-white shadow-md rounded-lg">
      <h2 className="text-xl font-semibold mb-2">Leaderboard</h2>
      <div className="overflow-x-auto overflow-y-auto max-h-[70vh]">
        {leaderboardData?.length > 0 ? (
          leaderboardData.map((user, index) => (
            <div key={index} className={'p-3 flex items-center justify-between border-t cursor-pointer hover:bg-gray-200'}>
              <div className="flex items-center">
                <MdOutlineLooksOne className="rounded-full h-10 w-10" />
                <div className="ml-2 flex flex-col">
                  <div className="leading-snug text-sm text-gray-900 font-bold">{user.names}</div>
                  <div className="leading-snug text-xs text-gray-600">{user.email}</div>
                </div>
              </div>
              <div className="flex flex-col items-end">
                <div className="leading-snug text-xs text-gray-600">Marks: {Math.round(user.combined_marks)}</div>
                <div className="leading-snug text-sm text-gray-900 font-bold">Tests done: {Math.round(user.subjects_count)}</div>
              </div>
            </div>
          ))
        ) : (
          (leaderboardloader) ? (<Spinner size="16" color="blue" />):(<div className="p-3 text-center text-gray-600">No users found in the leaderboard.</div>)
        )}
      </div>
    </div>
  );
};

export default Leaderboard;