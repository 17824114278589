import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { useLocation } from "react-router-dom";
import { getOneUserbyId } from "../../../../store/actions/users";
import { Button, GoBackButton } from "../../../elements/button";
import { ImageIcon } from "../../../elements/imageicon";
import Input from "../../../elements/input";
import { CardTitle, FeatureTitle } from "../../../elements/text";
import PageContent from "../pageContent";
import { useHistory } from 'react-router-dom';
import { combinations } from "../../../../functions/combinationsAndSubjects";
import DatePicker from 'react-datepicker';
import axios from "../../../../axios-base";
import Spinner from "../../../elements/spinner";
import { use } from "i18next";
import { BsEye, BsEyeSlash } from "react-icons/bs";

import { useTranslation } from "react-i18next";

const UserInfo = () => {
  const dispatch = useDispatch();
  const userAuth = useSelector((state) => state.auth);
  const location = useLocation();
  const { t } = useTranslation();
  const userInfo = useSelector((state) => state.usersInformation);
  const [gender, setGender] = useState("");
  const [combination, setCombination] = useState([]);
  const [dateOfBirth, setDateOfBirth] = useState(null);
  const [academicLevel, setAcademicLevel] = useState(1);
  const [firstname, setFirstname] = useState("");
  const [lastname, setLastname] = useState("");
  const [guardianName, setGuardianName] = useState("");
  const [guardianTel, setGuardianTel] = useState("");
  const [telephone, setTelephone] = useState("");
  const [password, setPassword] = useState("");
  const [comfirmPassword, setComfirmPassword] = useState("");
  const [isShowPassword, setIsShowPassword] = useState(false);
  const [isShowConfirmPassword, setIsShowConfirmPassword] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  // const userAuth = useSelector((state) => state.auth);
  const { usersError, usersLoading, user } = userInfo;
  const history = useHistory();

  const validatebirthdate = (date) => {
    // console.log("date consle",date)
    if (date !== null && date !== undefined) {
      setDateOfBirth(new Date(date));
    } else {
      setDateOfBirth(new Date("01-01-1990"));
    }

  }

  useEffect(() => {
    if (user) {
      //console.log("User:",typeof(user.dateofbirth));
      setGender(user.gender)
      setDateOfBirth(new Date(user.dateofbirth))
      setAcademicLevel(user.academic_level)
      setFirstname(user.names)
      setLastname(user.lastname)
      setGuardianName(user.guardian_name)
      setGuardianTel(user.guardian_tel)
      setTelephone(user.telephone)
      setCombination(user.combination)
    }
  }, [user]);
  useEffect(() => {
    try {
      const { userId } = location.state;
      setTimeout(() => {
        dispatch(getOneUserbyId(userId));
      }, 200);

    } catch (error) {
      // Go back to previous page if there is an error
      history.goBack();

      // console.log(error)
    }
  }, [dispatch, history, location.state]);
  if (usersLoading) {
    return (
      <PageContent>
        <div className="p-3 bg-white-blue space-y-2">
          <FeatureTitle name="User management" color="blue" />
          <div className="flex flex-col md:flex-row justify-between">
            <GoBackButton
              link="/school/features/users"
              action="Back"
              location={`Users: Manage users`}
            />
          </div>
          <div className="md:grid grid-cols-3 gap-2">
            <div>Loading...</div>
          </div>
        </div>
      </PageContent>
    );
  }

  if (false) {
    return (
      <PageContent>
        <div className="p-3 bg-white-blue space-y-2 max-h-myscreen overflow-auto scrollbar-thin scrollbar-thumb-gray-500 scrollbar-track-gray-100">
          <FeatureTitle name="User management" color="blue" />
          <div className="flex flex-col md:flex-row justify-between">
            <GoBackButton
              link="/school/features/users"
              action="Back"
              location={`Users: Manage users`}
            />
          </div>
          <div className="md:grid grid-cols-3 gap-2">
            <div>Error: {usersError}</div>
          </div>
        </div>
      </PageContent>
    );
  }
  const updateInformation = () => {
    // Validate the form
    if (!firstname || !lastname || !dateOfBirth || !gender || !telephone || !guardianName || !guardianTel) {
      alert("Please fill in all required fields.");
      return;
    }

    // Validate the phones to have 10 digits and or + sign
    if (!telephone.match(/^[0-9+]{10,}$/)) {
      alert("Please enter a valid phone number.");
      return;
    }
    if (!guardianTel.match(/^[0-9+]{10,}$/)) {
      alert("Please enter a valid phone number.");
      return;
    }

    // Proceed with form submission logic
    setIsSaving(true);
    const dataToSubmit = {
      names: firstname,
      lastname: lastname,
      dateofbirth: dateOfBirth,
      guardian_name: guardianName,
      guardian_tel: guardianTel,
      telephone: telephone,
      gender: gender,
      academic_level: academicLevel,
      email: user.email,
      combination: combination,
    }

    axios
      .post(`/opanda/manage-users/updateInformation`, {
        ...dataToSubmit
      },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${userAuth.token}`,
          },
        })
      .then((response) => {
        setIsSaving(false);
        alert("User information updated successfully.");
        history.push("/school/features/users");

      })
      .catch((err) => {
        setIsSaving(false);

      });



  }
  const updatepassword = () => {
    // Validate the form
    if (!password) {
      alert("Please fill in all required fields.");
      return;
    }


    // Proceed with form submission logic
    setIsSaving(true);
    const dataToSubmit = {
      password: password,
      email: user.email,
    }

    axios
      .post(`/opanda/manage-users/updatePassword`, {
        ...dataToSubmit
      },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${userAuth.token}`,
          },
        })
      .then((response) => {
        setIsSaving(false);
        alert("User information updated successfully.");
        history.push("/school/features/users");

      })
      .catch((err) => {
        setIsSaving(false);

      });



  }
  if (user !== null) {
    return (
      <PageContent>
        <div className="p-3 bg-white-blue space-y-2 max-h-myscreen overflow-auto scrollbar-thin scrollbar-thumb-gray-500 scrollbar-track-gray-100">
          <FeatureTitle name="User management" color="blue" />
          <div className="flex flex-col md:flex-row justify-between">
            <GoBackButton
              link="/school/features/users"
              action="Back"
              location={`Users: Manage users`}
            />
          </div>
          <div className="md:grid grid-cols-3 gap-2">
            <Input
              label="First Name"
              elementType="input"
              elementConfig={{
                type: "text",
                placeholder: "Kevin",
                disabled: false,
              }}
              value={firstname}
              changed={setFirstname}
              validation={{ required: true, notEmpty: true }}
              shouldValidate
              error="First Name is required"
            />
            <Input
              label="Last Name"
              elementType="input"
              elementConfig={{
                type: "text",
                placeholder: "Kevin",
                disabled: false,
              }}
              value={lastname}
              changed={setLastname}
              validation={{ required: true, notEmpty: true }}
              shouldValidate
              error="Last Name is required"
            />
            <Input
              label="Email"
              elementType="input"
              elementConfig={{
                type: "text",
                placeholder: "Kevin",
                disabled: true,
              }}
              value={user.email}
              validation={{ required: true, notEmpty: true }}
              shouldValidate
              error="Email is required"
            />
            {/* <DatePicker
              selected={dateOfBirth}
              onChange={(date) => { setDateOfBirth(date) }}
              dateFormat="dd/MM/yyyy"
              placeholderText="Select a date"
              isClearable
            /> */}
            {/* <CardTitle name="Date of birth" color="blue" /> */}
            {/* <Input
              label="Date of birth"
              elementType="input"
              elementConfig={{
                type: "text",
                placeholder: "Kevin",
                disabled: true,
              }}
              value={user.dateofbirth}
              validation={{ required: true, notEmpty: true }}
              shouldValidate
              error="First Name is required"
            /> */}
            <Input
              label={`Date of birth`}
              elementType="datepicker"
              elementConfig={
                {
                  selected: dateOfBirth,
                  onChange: (date) => { setDateOfBirth(date) },
                  dateFormat: "dd/MM/yyyy",
                  placeholderText: "Select a date",
                  isClearable: true
                }}
              value={dateOfBirth}
              // validatebirthdate={validatebirthdate}
              changed={validatebirthdate}
              validation={{ required: true }}
              shouldValidate
              // validation={{ required: true }}
              error="Date of birth is not valid"
              small

            />
            <Input
              label="Gender"
              elementType="select"
              elementConfig={{
                startingValue: "DEFAULT",
                optionsType: "minimal",
                options: ["M", "F"],
              }}
              value={gender}
              changed={setGender}
              validation={{ required: true }}
              error="Gender is required"
              small
            />
            {/* <Input
              label={`Gender${user.gender}`}
              elementType="input"
              elementConfig={{
                type: "text",
                placeholder: "Kevin",
                disabled: true,
              }}
              value={user.gender}
              validation={{ required: true, notEmpty: true }}
              shouldValidate
              error="First Name is required"
            /> */}
            {/* <Input
              label="Section"
              elementType="input"
              elementConfig={{
                type: "text",
                placeholder: "Kevin",
                disabled: true,
              }}
              value={user.section}
              validation={{ required: true, notEmpty: true }}
              shouldValidate
              error="First Name is required"
            /> */}
            {/* <Input
              label="Combination"
              elementType="input"
              elementConfig={{
                type: "text",
                placeholder: "Kevin",
                disabled: true,
              }}
              value={user.combination}
              validation={{ required: true, notEmpty: true }}
              shouldValidate
              error="First Name is required"
            /> */}
            <Input
              label={`Combination`}
              elementType="select"
              elementConfig={{
                startingValue: "N/A",
                optionsType: "minimal",
                options: [...combinations.map((comb) => comb.value)],
              }}
              value={combination}
              changed={setCombination}
              validation={{ required: false }}
              small
            />
            <Input
              label="Academic level"
              elementType="select"
              elementConfig={{
                startingValue: "1",
                optionsType: "minimal",
                options: [1, 2, 3, 4, 5, 6],
              }}
              value={academicLevel}
              changed={setAcademicLevel}
              validation={{ required: false }}
              small
            />
            {/* <Input
              label="Academic level"
              elementType="input"
              elementConfig={{
                type: "text",
                placeholder: "Kevin",
                disabled: true,
              }}
              value={user.academic_level}
              validation={{ required: true, notEmpty: true }}
              shouldValidate
              error="First Name is required"
            /> */}
            <Input
              label="User type"
              elementType="input"
              elementConfig={{
                type: "text",
                placeholder: "Kevin",
                disabled: true,
              }}
              value={user.user_type}
              validation={{ required: true, notEmpty: true }}
              shouldValidate
              error="User type is required"
            />
            <Input
              label="Guardian name"
              elementType="input"
              elementConfig={{
                type: "text",
                placeholder: "Kevin",
                disabled: false,
              }}
              value={guardianName}
              changed={setGuardianName}
              validation={{ required: true, notEmpty: true }}
              shouldValidate
              error="Guardian Name is required"
            />
            <Input
              label="Guardian telephone"
              elementType="input"
              elementConfig={{
                type: "text",
                placeholder: "Kevin",
                disabled: false,
              }}
              value={guardianTel}
              changed={setGuardianTel}
              validation={{ required: true, notEmpty: true }}
              shouldValidate
              error="Guardian telephone is required"
            />
            {/* <Input
              label="Activation code"
              elementType="input"
              elementConfig={{
                type: "text",
                placeholder: "Kevin",
                disabled: true,
              }}
              value={user.activation_code}
              validation={{ required: true, notEmpty: true }}
              shouldValidate
              error="First Name is required"
            /> */}
            {/* <Input
              label="Province"
              elementType="input"
              elementConfig={{
                type: "text",
                placeholder: "Kevin",
                disabled: true,
              }}
              value={user.province}
              validation={{ required: true, notEmpty: true }}
              shouldValidate
              error="First Name is required"
            /> */}
            {/* <Input
              label="District"
              elementType="input"
              elementConfig={{
                type: "text",
                placeholder: "Kevin",
                disabled: true,
              }}
              value={user.district}
              validation={{ required: true, notEmpty: true }}
              shouldValidate
              error="First Name is required"
            /> */}
            {/* <Input
              label="Sector"
              elementType="input"
              elementConfig={{
                type: "text",
                placeholder: "Kevin",
                disabled: true,
              }}
              value={user.sector}
              validation={{ required: true, notEmpty: true }}
              shouldValidate
              error="First Name is required"
            /> */}
            <Input
              label="Joined on"
              elementType="input"
              elementConfig={{
                type: "text",
                placeholder: "Kevin",
                disabled: true,
              }}
              value={user.created_at}
              validation={{ required: true, notEmpty: true }}
              shouldValidate
              error="Joined on is required"
            />
            {/* <Input
              label="Guardian on"
              elementType="input"
              elementConfig={{
                type: "text",
                placeholder: "Kevin",
                disabled: true,
              }}
              value={user.guardian_tel_guardian}
              validation={{ required: true, notEmpty: true }}
              shouldValidate
              error="First Name is required"
            /> */}
            {/* <Input
              label="Country"
              elementType="input"
              elementConfig={{
                type: "text",
                placeholder: "Kevin",
                disabled: true,
              }}
              value={user.country}
              validation={{ required: true, notEmpty: true }}
              shouldValidate
              error="First Name is required"
            /> */}
            {/* <Input
              label="Reference ID"
              elementType="input"
              elementConfig={{
                type: "text",
                placeholder: "Kevin",
                disabled: true,
              }}
              value={user.country}
              validation={{ required: true, notEmpty: true }}
              shouldValidate
              error="First Name is required"
            /> */}
            {/* <Input
              label="Login method"
              elementType="input"
              elementConfig={{
                type: "text",
                placeholder: "Kevin",
                disabled: true,
              }}
              value={user.login_method}
              validation={{ required: true, notEmpty: true }}
              shouldValidate
              error="First Name is required"
            /> */}
            <Input
              label="Telephone"
              elementType="input"
              elementConfig={{
                type: "text",
                placeholder: "Kevin",
                disabled: false,
              }}
              value={telephone}
              changed={setTelephone}
              validation={{ required: true, notEmpty: true }}
              shouldValidate
              error="Phone number is required"
            />

            

            {/* <Input
              label="Profile Completed"
              elementType="input"
              elementConfig={{
                type: "text",
                placeholder: "Kevin",
                disabled: true,
              }}
              value={user.isprofilecompleted ? 'yes' : 'no'}
              validation={{ required: true, notEmpty: true }}
              shouldValidate
              error="First Name is required"
            /> */}
            {/* <img
              alt="User profile"
              className="h-96px w-auto m-auto"
              src={user.avatarimage}
            /> */}
          </div>
        </div>
        <div className="flex justify-center items-center">
          {(!isSaving) ? <Button
            type="button"
            name={"Update information"}
            outline="true"
            color="blue"
            clicked={() => {
              updateInformation()
            }}
          /> : <Spinner size="20" />}
        </div>

        <div className="flex justify-start items-start m-3 "><div className="w-50 h-20"><Input
              elementType="input"
              withIcon
              elementConfig={{
                type: isShowPassword ? "text" : "password",
                placeholder: "Change password",
              }}
              value={password}
              changed={setPassword}
              validation={{ required: true, minLength: 5 }}
              shouldValidate
              error={("Password must be at least 5 characters")}
            >
              {!isShowPassword ? (
                <BsEye
                  className="cursor-pointer"
                  onClick={() => setIsShowPassword(!isShowPassword)}
                />
              ) : (
                <BsEyeSlash
                  className="cursor-pointer"
                  onClick={() => setIsShowPassword(!isShowPassword)}
                />
              )}
            </Input></div>
            <div className="flex justify-center items-center mt-3">
              {(!isSaving) ? <Button
                type="button"
                name={"Update password"}
                outline="true"
                color="blue"
                clicked={() => {
                  updatepassword()
                }}
              /> : <Spinner size="20" />}
            </div></div>

      </PageContent>
    );
  }
  return (
    <PageContent>
      <div>No data...</div>
    </PageContent>
  );
};

export default UserInfo;
